<template>
  <v-col>
    <v-toolbar elevation="0">
      <v-toolbar-title class="headline my-4"> Past appointments </v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-data-table
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        :headers="headers"
        :items="appointments"
        :loading="loading"
        :options.sync="currentOptions"
        class="elevation-1"
      >
        <template v-slot:item.start="{ item }">
          {{ prettyDate(item.start) }}
        </template>

        <template v-slot:item.time="{ item }">
          {{ prettyTime(item.start) }} to {{ prettyTime(item.end) }}
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>
<script>
export default {
  name: 'UpcomingAppointments',
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Date', value: 'start' },
      { text: 'Time', value: 'time' },
      { text: 'status', value: 'status' },
    ],
    currentOptions: {},
  }),
  props: {
    appointments: Array,
    itemsPerPageOptions: Array,
    loading: Boolean,
    prettyDate: Function,
    prettyTime: Function,
    tableOptions: Object,
  },
  mounted() {
    this.currentOptions = this.tableOptions;
  },
};
</script>
