<template>
  <v-container>
    <v-row class="mb-10">
      <upcoming-appointments
        :appointments="appointmentTypes.upcoming"
        :itemsPerPageOptions="itemsPerPageOptions"
        :loading="loading"
        :prettyDate="prettyDate"
        :prettyTime="prettyTime"
        :tableOptions="tableOptions"
      ></upcoming-appointments>
    </v-row>

    <v-row>
      <past-appointments
        :appointments="appointmentTypes.past"
        :itemsPerPageOptions="itemsPerPageOptions"
        :loading="loading"
        :prettyDate="prettyDate"
        :prettyTime="prettyTime"
        :tableOptions="tableOptions"
      ></past-appointments>
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment';
import to from 'await-to-js';

import { MEMBER } from '../constants/roles';
import { PAGE_SIZE_OPTIONS } from '../constants/page';
import { PRETTY_DATE_FORMAT, TIME_FORMAT_24 } from '../constants/moment';
import GET_ALL_APPOINTMENTS from '../graphql/Query/GetAllAppointments.gql';
import PastAppointments from '../components/PastAppointments.vue';
import UpcomingAppointments from '../components/UpcomingAppointments.vue';

export default {
  name: 'History',
  components: {
    PastAppointments,
    UpcomingAppointments,
  },
  data: () => ({
    loading: true,
    headers: {},
    appointments: [],
    today: moment().unix(),
    tableOptions: {
      itemsPerPage: PAGE_SIZE_OPTIONS[0],
      mustSort: true,
      page: 1,
      sortBy: ['start'],
      sortDesc: [true],
    },
    itemsPerPageOptions: PAGE_SIZE_OPTIONS,
  }),
  computed: {
    appointmentTypes() {
      return this.appointments.reduce(
        (obj, appointment) => {
          const where = appointment.end > this.today ? 'upcoming' : 'past';
          obj[where].push(appointment);
          return obj;
        },
        {
          upcoming: [],
          past: [],
        }
      );
    },
    userInfo() {
      return this.$store.getters['patientPortal/currentUser'];
    },
  },
  methods: {
    prettyDate(time) {
      return moment.unix(time).format(PRETTY_DATE_FORMAT);
    },
    prettyTime(time) {
      return moment.unix(time).format(TIME_FORMAT_24);
    },
    async getAppointments() {
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_ALL_APPOINTMENTS,
          variables: {
            providerId: this.userInfo.providerId,
            userFilter: {
              id: this.userInfo.id,
              role: MEMBER,
            },
          },
          fetchPolicy: 'no-cache',
        })
      );

      this.loading = false;

      const newAppointment = query && query.data.getAllAppointmentsForProvider;

      if (errors || !newAppointment) {
        console.error(errors || 'No appointment');
        return;
      }

      this.appointments = newAppointment;
    },
  },
  async mounted() {
    if (this.userInfo.id) {
      this.getAppointments();
    }

    this.$store.watch(
      (state) => state.user.info.id,
      (value) => {
        if (value) {
          this.getAppointments();
        }
      }
    );
  },
};
</script>
